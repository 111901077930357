import DailyMenu from './DailyMenu';
import { Container, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useState, useEffect } from 'react'


function WeeklySchedule({ menu, selectedValues,ratings, comments, handleChangedItems, handleCheckboxChange }) {
  const [weekStart, setWeekStart] = useState(getMonday(new Date()));
  //const [weekStart, setWeekStart] = useState(getNextMonday(new Date()));
  const [currentWeekStart, setCurrentWeekStart] = useState(getMonday(new Date()));
  const [maxMenuDate, setMaxMenuDate] = useState(findMaxMenuDate(menu));

  //-----------------------------------------------------------------

  useEffect(() => {
    setMaxMenuDate(findMaxMenuDate(menu));
  }, [menu]);

  function findMaxMenuDate(menu) {
    let maxDate = new Date(0);
    menu.forEach(item => {
      const date = new Date(item.Date);
      if (date > maxDate) {
        maxDate = date;
      }
    });
    return maxDate;
  }

  function getMonday(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  function getPreviousMonday(date) {
    const newDate = new Date(date); 
    const day = newDate.getDay(); 
    const daysSinceLastMonday = day === 0 ? 6 : day - 1; 
    const daysToLastMondayOfPreviousWeek = daysSinceLastMonday + 7; 
    newDate.setDate(newDate.getDate() - daysToLastMondayOfPreviousWeek);
    return newDate;
  }
  

  function getNextMonday(date) {
    const newDate = new Date(date); 
    const day = newDate.getDay();
    const diff = day === 0 ? 1 : 8 - day; 
    newDate.setDate(newDate.getDate() + diff);
    //console.log(newDate)
    return newDate;
  }
  
  function handleNextWeek() {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() + 7);
    setWeekStart(getMonday(newWeekStart));
  }


  function handlePreviousWeek() {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() - 7);
    setWeekStart(getMonday(newWeekStart));
  }


   const getWeekDates = () => {
    const dates = [];
    const currentDate = new Date(weekStart);
    const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' };
    
    for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        const dayOfWeek = date.getDay();
        
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            //dates.push(date);
            dates.push(date.toLocaleDateString('en-US', options));
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;

};




  //----------------------------------------------

  const getMenuItem = (array2D, targetString) => {
    for (let i = 0; i < array2D.length; i++) {
      const formattedDate = new Date(array2D[i].Date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' });

      if (array2D[i] && formattedDate == targetString) {
        return [array2D[i].ScheduleItems];
    }
    }
    return null;
  };


  const generateWeeklyMenu = () => {
    const weeklyMenu = [];
    const weeklyDates = getWeekDates();
   
    for (let i = 0; i < weeklyDates.length; i++) {
      const item = [];
      item.push(weeklyDates[i]);
      //const menuitem = getMenuItem(menu, weeklyDates[i].toDateString())
      const menuitem = getMenuItem(menu, weeklyDates[i])

      if (menuitem) {
        item.push(menuitem[0]);
      }
      else
        item.push(menuitem);
      weeklyMenu.push(item);
    }
     //console.log(weeklyDates)
    // console.log(weeklyMenu)
    return weeklyMenu;
  };

  //const isPreviousDisabled = weekStart < new Date();
  const prevMonday = getPreviousMonday(new Date());
  const isPreviousDisabled = weekStart < prevMonday;
  const isNextDisabled = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000) >= maxMenuDate;


  return (
    
      <Container style={{ paddingTop: '12%' }}>
      <Row style={{ marginBottom: '3%', position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1000' }}>         
        <Col>
          <IconButton aria-label="Previous" disabled={isPreviousDisabled}>
            <ArrowBackIosIcon onClick={handlePreviousWeek}></ArrowBackIosIcon>
          </IconButton>
        </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <h4>Weekly Menu</h4></Col>
          <Col>
            <IconButton aria-label="Next" disabled={isNextDisabled}>
              <ArrowForwardIosIcon onClick={handleNextWeek}></ArrowForwardIosIcon>
            </IconButton>
          </Col>
        </Row>
        <Row></Row>
        <Row>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {generateWeeklyMenu().map((item, index) => (
              <DailyMenu
                key={index}
                //date={item[0].toDateString()}
                date={item[0]}
                menus={item[1]}
                selectedValues={selectedValues}
                ratings = {ratings}
                comments = {comments}
                thali = {1}
                handleCheckboxChange={handleCheckboxChange}
                handleChangedItems={handleChangedItems}
                currentWeekStart={currentWeekStart}
              >
              </DailyMenu>
            ))}
          </ul>
        </Row>
      </Container>
  );

            }

export default WeeklySchedule;



