import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';

function UserZone() {
  const [ZoneData, setZoneData] = useState([]);
  const [users, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addMode, setAddMode] = useState(true);
  const [rowData, setRowData] = useState({});
  const [selectedZone, setSelectedZone] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('username');

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserZoneData`, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          setUserData([]);
        } else {
          setUserData(response.data);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchZones = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAllZones`, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          setZoneData([]);
        } else {
          setZoneData(response.data);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateUserZone = async (FMBZoneId, ITS) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateUserZone`, { FMBZoneId, ITS,currentUser, currentUserName });
      if (response.data.success) {
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleEdit = (data) => {
    setAddMode(false);
    setRowData(data);
    setSelectedZone(data.FMBZoneId);
    setShowModal(true);
  };

  const handleAddNewZone = () => {
    setAddMode(true);
    setRowData({});
    setSelectedZone('');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRowData({});
    setSelectedZone('');
  };

  const handleSave = () => {
    const updatedData = { ...rowData, FMBZoneId: selectedZone };
    console.log('updatedData')
    console.log(updatedData)
    updateUserZone(updatedData.FMBZoneId, updatedData.ITS);
    setShowModal(false);
    setRowData({});
  };

  useEffect(() => {
    fetchData();
    fetchZones();
  }, []);

  const handleZoneChange = (e) => {
    setSelectedZone(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredUsers = users.filter(user => {
    const searchString = `${user.Name} ${user.ITS} ${user.Address} ${user.FMBZoneId} ${user.Title}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '70px', paddingBottom: '70px' }}>
        <Container className="mx-auto" style={{ maxWidth: '900px' }}>
        <Row className="mt-4">
            <Col xs={12}>
              <input
              style={{float:'left'}}
                type="text"
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterChange}
              />
            </Col>
          </Row>
          {users && users.length > 0 ? (
            
            <Row className="mt-4">
              <Col xs={12}>
                <div className="table-responsive">
                  <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px' }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>ITS Number</th>
                        <th>Address</th>
                        <th>Zone Id</th>
                        <th>Zone Title</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {users.map(user => ( */}
                      {filteredUsers.map(user => (
                        <tr key={user.UserId}>
                          <td>{user.Name}</td>
                          <td>{user.ITS}</td>
                          <td>{user.Address}</td>
                          <td>{user.FMBZoneId}</td>
                          <td>{user.Title}</td>
                          <td>
                            <Button variant="primary" onClick={() => handleEdit(user)}>
                              <EditIcon />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
            </Row>
          )}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Zone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="mb-3">
                  <label htmlFor="its" className="form-label">ITS Number</label>
                  <input type="text" className="form-control" id="its" value={rowData.ITS || ''} disabled />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={rowData.Name || ''} disabled />
                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input type="text" className="form-control" id="address" value={rowData.Address || ''} disabled />
                </div>
                <div className="mb-3">
                  <label htmlFor="zone" className="form-label">Zone</label>
                    <select className="form-select" id="zone" value={selectedZone} onChange={handleZoneChange}>
                    <option value="">Select Zone</option>
                    {ZoneData.map((zone) => (
                      <option key={zone.FMBZoneId} value={zone.FMBZoneId}>{`${zone.FMBZoneId} (${zone.Title})`}</option>
                    ))}
                  </select>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
              <Button variant="primary" onClick={handleSave}>Save changes</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <Footer />
    </>
  );


}

export default UserZone;
