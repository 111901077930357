import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import Header from './Header';
import Footer from './Footer';

function Privileges() {
  const [privileges, setPrivileges] = useState([]);
  const [showAddPrivilegeModal, setShowAddPrivilegeModal] = useState(false);
  const [showEditPrivilegeModal, setShowEditPrivilegeModal] = useState(false);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [newPrivilegeName, setNewPrivilegeName] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const handleAddPrivilegeModalClose = () => setShowAddPrivilegeModal(false);
  const handleEditPrivilegeModalClose = () => setShowEditPrivilegeModal(false);
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('username');

  const filteredPrivileges = privileges.filter(privilege => {
    const searchString = `${privilege.PrivilegeId} ${privilege.Description}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPrivileges`, {});
     // console.log(response.data)
      if (response.status === 200 ) {
      //  console.log(response.data)
        setPrivileges(response.data);
      } else {
        setPrivileges([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEdit = (privilege) => {
    setSelectedPrivilege(privilege);
    setNewPrivilegeName(privilege.Description); 
    setShowEditPrivilegeModal(true);
  };

  const handleSave = async () => {
    // console.log(selectedPrivilege)
    // console.log(newPrivilegeName)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updatePrivilege`, {
        PrivilegeId: selectedPrivilege.PrivilegeId,
        Description: newPrivilegeName,
      });
      if (response.status === 200) {
        setShowEditPrivilegeModal(false);
        setSelectedPrivilege(null);
        setNewPrivilegeName('');
        fetchData();
      } else {
        console.error('Error updating Privilege:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addPrivilege = async (Description) => {
    try {
      console.log(Description)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewPrivilege`, { Description, currentUser,currentUserName});
      if (response.data.success) {
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddPrivilege = async () => {

    try {
      await addPrivilege(newPrivilegeName);
      handleAddPrivilegeModalClose(); 
    } catch (error) {
      console.error('Error adding Privilege:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddNewPrivilege = () => {
    setShowAddPrivilegeModal(true);
  };

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '10px', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '900px', padding: '0 15px' }}>
          <Row className="mt-4">
            <Col xs={6}>
              <input
                type="text"
                style={{float:'left'}}
                placeholder="Search..."
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </Col>
            <Col xs={6} style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="contained" style={{ backgroundColor: 'green', borderRadius: '20px' }} onClick={handleAddNewPrivilege}>Add New Privilege</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div style={{ overflowX: 'auto' }}>
                <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                  <thead>
                    <tr>
                      <th>Privilege Id</th>
                      <th>Privilege Name</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPrivileges.map(privilege => (
                      <tr key={privilege.PrivilegeId}>
                        <td>{privilege.PrivilegeId}</td>
                        <td>{privilege.Description}</td>
                        <td>
                          <Button variant="primary" onClick={() => handleEdit(privilege)} startIcon={<EditIcon />}></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Modal show={showAddPrivilegeModal} onHide={handleAddPrivilegeModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Privilege</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formPrivilegeName">
                  <Form.Label>Privilege Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Privilege" value={newPrivilegeName} onChange={(e) => setNewPrivilegeName(e.target.value)} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAddPrivilegeModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddPrivilege}>Add Privilege</Button>
            </Modal.Footer>
          </Modal>
    


          <Modal show={showEditPrivilegeModal} onHide={handleEditPrivilegeModalClose}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Privilege</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formPrivilegeId">
        <Form.Label>Privilege ID</Form.Label>
        <Form.Control type="text" placeholder="Privilege ID" value={selectedPrivilege ? selectedPrivilege.PrivilegeId : ''} readOnly />
      </Form.Group>
      <Form.Group controlId="formPrivilegeName">
        <Form.Label>Privilege Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Privilege" value={newPrivilegeName} onChange={(e) => setNewPrivilegeName(e.target.value)} />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleEditPrivilegeModalClose}>Close</Button>
    <Button variant="primary" onClick={handleSave}>Save Changes</Button>
  </Modal.Footer>
</Modal>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Privileges;
