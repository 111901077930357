import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from './Header';
import Footer from './Footer';

function RolePrivileges() {
  const [rolePrivileges, setRolePrivileges] = useState([]);
  const [roles, setRoles] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [showAddRolePrivilegeModal, setShowAddRolePrivilegeModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRolePrivilege, setSelectedRolePrivilege] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [selectedPrivilegeId, setSelectedPrivilegeId] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('username');

  const handleAddRolePrivilegeModalClose = () => {
    setShowAddRolePrivilegeModal(false);
    setErrorMessage('');
  };
  const handleDeleteConfirmModalClose = () => setShowDeleteConfirmModal(false);

  const filteredRolePrivileges = rolePrivileges.filter(rolePrivilege => {
    const searchString = `${rolePrivilege.RolePrivilegeId} ${rolePrivilege.RoleId} ${rolePrivilege.PrivilegeId} ${rolePrivilege.RoleName} ${rolePrivilege.Description}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRolePrivileges`, {});
      if (response.status === 200) {
        setRolePrivileges(response.data);
      } else {
        setRolePrivileges([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const getRoles = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRoles`, {});
      if (response.status === 200) {
        setRoles(response.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getPrivileges = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPrivileges`, {});
      if (response.status === 200) {
        setPrivileges(response.data);
      } else {
        setPrivileges([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDelete = (rolePrivilege) => {
    setSelectedRolePrivilege(rolePrivilege);
    setShowDeleteConfirmModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateRolePrivilege`, {
        RolePrivilegeId: selectedRolePrivilege.RolePrivilegeId,
        Enabled: 0,
        currentUser: currentUser,
        currentUserName: currentUserName
      });
      if (response.status === 200) {
        setSelectedRolePrivilege(null);
        fetchData();
      } else {
        console.error('Error updating rolePrivilege:', response.data.message);
      }
      setShowDeleteConfirmModal(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addRolePrivilege = async (roleId, privilegeId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewRolePrivilege`, { RoleId: roleId, PrivilegeId: privilegeId, currentUser: currentUser, currentUserName:currentUserName });
      if (response.data.success) {
        setSelectedRoleId('');
        setSelectedPrivilegeId('');
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddRolePrivilege = async () => {
    if (selectedRoleId && selectedPrivilegeId) {
      try {
        await addRolePrivilege(selectedRoleId, selectedPrivilegeId);
        handleAddRolePrivilegeModalClose();
      } catch (error) {
        console.error('Error adding rolePrivilege:', error);
      }
    } else {
      setErrorMessage('Both role and privilege must be selected.');
    }
  };

  useEffect(() => {
    fetchData();
    getRoles();
    getPrivileges();
  }, []);

  // useEffect(() => {
  //   const abortFetchData = fetchData();
  //   const abortFetchRoles = getRoles();
  //   const abortFetchPrivileges = getPrivileges();

  //   return () => {
  //     abortFetchData();
  //     abortFetchRoles();
  //     abortFetchPrivileges();
  //   };
  // }, []);

  const handleAddNewRolePrivilege = () => {
    setShowAddRolePrivilegeModal(true);
  };

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '900px', padding: '0 15px' }}>
          <Row className="">
          <Col xs={12} md={6} style={{marginTop:'10px'}}>
              <input
                type="text"
                style={{ width: '100%' }}
                placeholder="Search..."
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </Col>
            <Col md={2}></Col>
          <Col xs={12} md={4} style={{float:'right', textAlign: 'right',marginTop:'10px', marginBottom: '15px' }}>
              <Button variant="contained" style={{ backgroundColor: 'green', borderRadius: '20px' }} onClick={handleAddNewRolePrivilege}>Add New Role Privilege</Button>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div style={{ overflowX: 'auto' }}>
                <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                  <thead>
                    <tr>
                      <th>Role Privilege Id</th>
                      <th>Role Id</th>
                      <th>Role</th>
                      <th>Privilege Id</th>
                      <th>Privilege</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRolePrivileges.map(rolePrivilege => (
                      <tr key={rolePrivilege.RolePrivilegeId}>
                        <td>{rolePrivilege.RolePrivilegeId}</td>
                        <td>{rolePrivilege.RoleId}</td>
                        <td>{rolePrivilege.RoleName}</td>
                        <td>{rolePrivilege.PrivilegeId}</td>
                        <td>{rolePrivilege.Description}</td>
                        <td>
                          <Button variant="danger" onClick={() => handleDelete(rolePrivilege)} startIcon={<DeleteIcon />}></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Modal show={showAddRolePrivilegeModal} onHide={handleAddRolePrivilegeModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Role Privilege</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
              <Form>
                <Form.Group controlId="formRoleSelect">
                  <Form.Label>Role</Form.Label>
                  <Form.Control as="select" value={selectedRoleId} onChange={(e) => setSelectedRoleId(e.target.value)}>
                    <option value="">Select Role</option>
                    {roles.map(role => (
                      <option key={role.RoleId} value={role.RoleId}>{role.RoleName}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formPrivilegeSelect" className="mt-3">
                  <Form.Label>Privilege</Form.Label>
                  <Form.Control as="select" value={selectedPrivilegeId} onChange={(e) => setSelectedPrivilegeId(e.target.value)}>
                    <option value="">Select Privilege</option>
                    {privileges.map(privilege => (
                      <option key={privilege.PrivilegeId} value={privilege.PrivilegeId}>{privilege.Description}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAddRolePrivilegeModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddRolePrivilege}>Add Role Privilege</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDeleteConfirmModal} onHide={handleDeleteConfirmModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Role Privilege</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this role privilege?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteConfirmModalClose}>No</Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>Yes</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RolePrivileges;
