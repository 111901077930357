import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import TextField from '@mui/material/TextField';


import { formatISO, addDays, startOfWeek, addWeeks, endOfYear } from 'date-fns';

const ExcelJS = require('exceljs');

function MenuMaker() {
  const [menuData, setMenuData] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [updatedMenu, setUpdatedMenu] = useState([]);
  const [updatedSchedule, setUpdatedSchedule] = useState([]);
  const [itemsToRemove, setItemsToRemove] = useState([]);
  
  const [message, setmessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [dateFrom, setFromDate] = useState('');
  const [dateTo, setToDate] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('username');
  
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilNextMonday = 1 - dayOfWeek + 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);

  const currentYear = new Date().getFullYear();
  const endOfYear = new Date(currentYear, 11, 31);


  useEffect(() => {
    if (dateFrom && dateTo) {
      fetchMenuAndSchedule();
    }
    else fetchFoodItems();
  }, [dateFrom, dateTo]);

  const fetchMenuAndSchedule = async () => {
    try {
      await Promise.all([fetchFoodItems(), fetchSchedule()]);
    } catch (error) {
      console.error('Error fetching menu and schedule:', error);
    }
  };


  const handleFromDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    if (dateTo && selectedDate > new Date(dateTo)) {
      setErrorMsg('From date must be before To date.');
    } else {
      setErrorMsg('');
      setFromDate(event.target.value);
    }
  };

  const handleToDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    if (dateFrom && selectedDate < new Date(dateFrom)) {
      setErrorMsg('To date must be after From date.');
    } else {
      setErrorMsg('');
      setToDate(event.target.value);
    }
  };

  const fetchFoodItems = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getFoodData`, {});
      if (response.data.success) {
        setMenuData(response.data.menuItems);
      }
    } catch (error) {
      console.error('Error fetching food items:', error);
    }
  };

  const updateFoodItems = async (jsonData) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateFoodItems`, { menuItems: jsonData, currentUser: currentUser, currentUserName: currentUserName });
      if (response.data.success) {
        setmessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      setLoading(false);
    }
  };


  const downloadFoodExcel = async () => {
    try {
      //fetchFoodItems();
      await fetchFoodItems();
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Menu Data');
  
      const headers = ['FoodId', 'Name', 'Category', 'Description', 'isActive'];
      worksheet.addRow(headers);
  
      const validation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"true,false"']
      };
  
      const isActiveColumnIndex = headers.indexOf('isActive') + 1;
      const rowCount = 9999;
      for (let rowIndex = 2; rowIndex <= rowCount + 1; rowIndex++) {
        worksheet.getCell(rowIndex, isActiveColumnIndex).dataValidation = validation;
      }
  
      menuData.forEach((item, index) => {
        const rowIndex = index + 2;
        headers.forEach((header, columnIndex) => {
          //worksheet.getCell(rowIndex, columnIndex + 1).value = item[header] ? item[header].toString() : '';
          if (header === 'isActive') {
            const isActiveValue = item[header];//=== true ? true : item[header] === false ? false : '';
            worksheet.getCell(rowIndex, columnIndex + 1).value = isActiveValue;
          } else {
            worksheet.getCell(rowIndex, columnIndex + 1).value = item[header] ? item[header].toString() : '';
          }
        });
      });
  
      // Adjust column widths based on content
      headers.forEach((header, columnIndex) => {
        let maxLength = header.length;
        menuData.forEach(item => {
          const value = item[header] ? item[header].toString() : '';
          if (value.length > maxLength) {
            maxLength = value.length;
          }
        });
        worksheet.getColumn(columnIndex + 1).width = maxLength + 2; // Add some padding
      });
  
      const filename = 'fmb_menu.xlsx';
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  


  const handleFileChangeFood = async (e) => {
    try {
      const file = e.target.files[0];

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0]; // Assuming data is in the first worksheet

      const jsonData = [];
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) { // Skip header row
          const rowData = {};
          row.eachCell((cell, colNumber) => {
            const headerCell = worksheet.getRow(1).getCell(colNumber);
            rowData[headerCell.value] = cell.value;
          });
          jsonData.push(rowData);
        }
      });

      setUpdatedMenu(jsonData);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFoodUpload = async () => {
    updateFoodItems(updatedMenu);
  };

  //--------------------------------------------

  const fetchSchedule = async () => {
    try {
      const fromDate = dateFrom.replaceAll('-','/');
      const toDate = dateTo.replaceAll('-','/');
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUpdatedSchedule`, { toDate, fromDate });
      if (response.data.success) {
        setSchedule(response.data.schedule);
      }
    } catch (error) {
      console.error('Error fetching schedule:', error);
    }
  };

  const updateSchedule = async (jsonData) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateSchedule`, { schedule: jsonData, currentUser: currentUser, currentUserName: currentUserName });
      if (response.data.success) {
        setmessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const removeScheduleItems = async (itemsToRemove) => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/removeScheduleItems`, { itemsToRemove });
      if (response.data.success) {
        setmessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      setLoading(false);
    }

  };
  

  const downloadScheduleExcel = async () => {
    try {
      if (!dateFrom || !dateTo) {
        setErrorMsg('Please select both From and To dates.');
        return;
      } else {
        setErrorMsg('');
        // setFromDate(dateFrom.replaceAll('-','/'));
        // setToDate(dateTo.replaceAll('-','/'));
      }
    
  
      await fetchMenuAndSchedule();
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Schedule');
  
      const headers = ['ScheduleId', 'Date', 'Food', 'FoodId'];
      worksheet.addRow(headers);

      worksheet.getColumn('B').numFmt = 'dd/mm/yyyy';

      let activeMenuData = menuData.filter(food => food.isActive);
  
      let foodDropdownOptions = activeMenuData.map(food => food.Name);
      let foodIdOptions = activeMenuData.map(food => food.FoodId);
  
      let currentDate = new Date(dateFrom.replace('-','/'));
     const endDate = new Date(dateTo.replace('-','/'));
     
      while (currentDate <= endDate) {
        const formattedDate = currentDate.toLocaleDateString('en-GB');
      
        const currentDateWithoutTime = new Date(currentDate);
        currentDateWithoutTime.setHours(0, 0, 0, 0);
     
        const dailySchedule = schedule.filter((item) => {
          const itemDate = new Date((item.Date.split('T')[0]).replaceAll('-','/'));
          itemDate.setHours(0, 0, 0, 0);
          return itemDate.getTime() === currentDateWithoutTime.getTime();
        });
  
        dailySchedule.forEach((item, index) => {
          const selectedFoodName = menuData.find((food) => food.FoodId === item.FoodId)?.Name || '';
          worksheet.addRow([item.ScheduleId, formattedDate, selectedFoodName, item.FoodId]);
        });
  
        for (let i = dailySchedule.length; i < 3; i++) {
          worksheet.addRow(['', formattedDate, '', '']);
        }
  
        currentDate = addDays(currentDate, 1);
      }
  
      const dropdownSheet = workbook.addWorksheet('DropdownOptions');
      for (let i = 0; i < foodDropdownOptions.length; i++) {
        dropdownSheet.getCell(`A${i + 1}`).value = foodDropdownOptions[i];
        dropdownSheet.getCell(`B${i + 1}`).value = foodIdOptions[i];
      }
      dropdownSheet.state = 'veryHidden';
  
      const foodColumn = worksheet.getColumn('C');
      const dropdownRange = `DropdownOptions!$A$1:$A$${foodDropdownOptions.length}`;
  
      foodColumn.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [dropdownRange],
            showErrorMessage: true,
            errorTitle: 'Invalid selection',
            error: 'Please select a value from the dropdown list.'
          };
        }
      });
  
      const foodIdColumn = worksheet.getColumn('D');
      foodIdColumn.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) {
          cell.value = { formula: `IF(C${rowNumber}="","",VLOOKUP(C${rowNumber},DropdownOptions!A:B,2,FALSE))` };
        }
      });
  
    //  foodIdColumn.hidden = true;
  
      // Adjust column widths based on content
      headers.forEach((header, columnIndex) => {
        let maxLength = header.length;
        if (header === 'Food') {
          foodDropdownOptions.forEach(food => {
            if (food.length > maxLength) {
              maxLength = food.length;
            }
          });
        } else {
          schedule.forEach(item => {
            const value = item[header] ? item[header].toString() : '';
            if (value.length > maxLength) {
              maxLength = value.length;
            }
          });
        }
        worksheet.getColumn(columnIndex + 1).width = maxLength + 2; 
      });
  
      //-------locking-----------
      worksheet.protect('', {
        selectLockedCells: true,
        selectUnlockedCells: true,
        formatCells: false,
        formatColumns: true,
        formatRows: false,
        insertColumns: false,
        insertRows: false,
        insertHyperlinks: false,
        deleteColumns: false,
        deleteRows: false,
        sort: false,
        autoFilter: false,
        pivotTables: false,
      });
  
      worksheet.getColumn('B').eachCell((cell) => {
        cell.protection = { locked: false };
      });
      worksheet.getColumn('C').eachCell((cell) => {
        cell.protection = { locked: false };
      });
      worksheet.getColumn('D').eachCell((cell) => {
        cell.protection = { locked: false };
      });
      //-------------------------
  
      const filename = 'fmb_schedule.xlsx';
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, filename);
  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const convertDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  };


  const handleFileChangeSchedule = async (e) => {
    try {
      const file = e.target.files[0];

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0];

      const jsonData = [];
      const itemsToRemove = [];

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          const rowData = {};
          row.eachCell((cell, colNumber) => {
            const headerCell = worksheet.getRow(1).getCell(colNumber);
            const header = headerCell.value;
  
            if (header === 'Date') {
              rowData[header] = convertDate(cell.value);
            } else {
              rowData[header] = cell.value;
            }
            //rowData[headerCell.value] = cell.value;
          });
          //jsonData.push(rowData);
          if (!rowData['Food']) {
            itemsToRemove.push(rowData);
          } else {
            jsonData.push(rowData);
          }
        }
      });


      setUpdatedSchedule(jsonData);
      setItemsToRemove(itemsToRemove);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleScheduleUpload = async () => {
    await updateSchedule(updatedSchedule);

    if (itemsToRemove.length > 0) {
      await removeScheduleItems(itemsToRemove);
    }
  };

  useEffect(() => {
    // Perform actions based on activeTab
    if (activeTab === 'food') {
      // Do something for food tab
    } else if (activeTab === 'schedule') {
      // Do something for schedule tab
    }
  }, [activeTab]);


  return (
    <div style={{ marginTop: '70px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', flexWrap: 'wrap' }}>
        <Button variant="contained" color="primary" onClick={() => setActiveTab('food')} style={{ margin: '10px', backgroundColor: 'green' }}>Update Food Items</Button>
        <Button variant="contained" color="primary" onClick={() => setActiveTab('schedule')} style={{ margin: '10px', backgroundColor: 'green' }}>Update Schedule</Button>
      </div>
      {activeTab === 'food' && (
        <div id="food" style={{ position: 'relative', marginTop: '20px', textAlign: 'center', width: '100%', maxWidth: '600px' }}>
          <Typography variant="h5" color={'green'} gutterBottom>
            Update Food Items:
          </Typography>
          <hr style={{ width: '100%', borderTop: '2px solid #ccc' }} />
          <Typography variant="subtitle1">
            Download Template for Food Items:
          </Typography>
          <div style={{ padding: '2%' }}>
            <Button variant="contained" color="primary" size="small" style={{ backgroundColor: 'green' }} onClick={downloadFoodExcel}>Download</Button>
          </div>
          <hr style={{ width: '100%', borderTop: '2px solid #ccc' }} />
          <Typography variant="subtitle1">
            Upload Data:
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <input type="file" accept=".xlsx,.xls" onChange={handleFileChangeFood} id="file-upload" />
            <Button variant="contained" color="primary" style={{ backgroundColor: 'green', marginTop: '10px' }} onClick={handleFoodUpload}>Upload</Button>
            {loading && <CircularProgress style={{ marginLeft: '10px' }} />}
            {message && <Typography variant="body1" style={{ marginTop: '20px' }}>{message}</Typography>}
          </div>
        </div>
      )}
      {activeTab === 'schedule' && (
        <div id="schedule" style={{ position: 'relative', marginTop: '20px', textAlign: 'center', width: '100%', maxWidth: '600px' }}>
          <Typography variant="h5" color={'green'} gutterBottom>
            Update Schedule:
          </Typography>
          <hr style={{ width: '100%', borderTop: '2px solid #ccc' }} />
          <div style={{ display: 'flex', justifyContent: 'center', margin: '10px', flexWrap: 'wrap' }}>
            <TextField
              id="dateFrom"
              label="From"
              type="date"
              value={dateFrom}
              onChange={handleFromDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: nextMonday.toISOString().split('T')[0],
                max: endOfYear.toISOString().split('T')[0],
              }}
              style={{ marginRight: '5%', marginBottom: '10px', flex: '1 1 45%' }}
            />
            <TextField
              id="dateTo"
              label="To"
              type="date"
              value={dateTo}
              onChange={handleToDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: nextMonday.toISOString().split('T')[0],
              }}
              style={{ marginBottom: '10px', flex: '1 1 45%' }}
            />
          </div>
          {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
          <Typography variant="subtitle1">
            Download Schedule:
          </Typography>
          <div style={{ padding: '2%' }}>
            <Button variant="contained" color="primary" size="small" style={{ backgroundColor: 'green' }} onClick={downloadScheduleExcel}>Download</Button>
          </div>
          <hr style={{ width: '100%', borderTop: '2px solid #ccc' }} />
          <Typography variant="subtitle1">
            Upload Data:
          </Typography>
          <Row className="justify-content-center" style={{ marginTop: '' }}>
          <Col xs={12} md={6} style={{marginTop:'20px', alignItems:'center', textAlign:'center'}}>
          <input type="file" accept=".xlsx,.xls" onChange={handleFileChangeSchedule} id="file-upload" />
          </Col>
          <Col md={2}></Col>
          <Col xs={12} md={4} style={{marginTop:'10px', marginBottom: '15px' }}>
          <Button variant="contained" color="primary" style={{ backgroundColor: 'green', marginTop: '10px' }} onClick={handleScheduleUpload}>Upload Schedule</Button>
            {loading && <CircularProgress style={{ marginLeft: '10px' }} />}
            {message && <Typography variant="body1" style={{ marginTop: '20px' }}>{message}</Typography>}
          </Col>
          </Row>
          {/* <div style={{ marginTop: '20px' }}>
            <input type="file" accept=".xlsx,.xls" onChange={handleFileChangeSchedule} id="file-upload" />
            <Button variant="contained" color="primary" style={{ backgroundColor: 'green', marginTop: '10px' }} onClick={handleScheduleUpload}>Upload Schedule</Button>
            {loading && <CircularProgress style={{ marginLeft: '10px' }} />}
            {message && <Typography variant="body1" style={{ marginTop: '20px' }}>{message}</Typography>}
          </div> */}
        </div>
      )}
      <Footer />
    </div>
  );



}

export default MenuMaker;
