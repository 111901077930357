import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { MDBContainer, MDBRow, MDBCard, MDBCol, MDBCardBody, MDBIcon } from 'mdb-react-ui-kit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import axios from 'axios';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function DailyMenu(props) {
  const [selectedValuesLocal, setSelectedValuesLocal] = useState(props.selectedValues);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [ratings, setRatings] = useState(props.ratings || {});
  const [comments, setComments] = useState(props.comments || {});
  const currentUser = localStorage.getItem('userId');
  const currentUserName = localStorage.getItem('username');

  const maxChars = 50;
  const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
  const previousFriday = new Date(today);
  previousFriday.setDate(today.getDate() - (dayOfWeek === 5 ? 0 : dayOfWeek + 2)); // Calculate the previous Friday correctly

  useEffect(() => {
    setSelectedValuesLocal(props.selectedValues);
  }, [props.selectedValues]);

  useEffect(() => {
    setRatings(props.ratings);
  }, [props.ratings]);
  
  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  const handleFeedbackDisabled = () => {
   // Parse props.date as a Date object
    const date = new Date(props.date);
    date.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)

    // Assuming previousFriday is also a Date object, set to midnight as well if needed
    const previousFridayWoDate = previousFriday.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Compare dates
    const bool = today < date;
    return bool;
  };

  const handleFeedbackFormDisabled = (scheduleId) => {
    const bool = !props.selectedValues[scheduleId] || !!props.ratings[scheduleId] || !!props.comments[scheduleId];
    return bool;
  };

  const handleChange = (value) => {
    const newSelectedValues = { ...selectedValuesLocal, [value]: !selectedValuesLocal[value] };
    setSelectedValuesLocal(newSelectedValues);
    props.handleCheckboxChange(newSelectedValues);
    props.handleChangedItems(value, newSelectedValues[value]);
  };

  const formatDate = () => {
    var date = props.date.split(/[ ,]+/);
    return date;
  };

  const isMenuLocked = (date) => {
    const incomingDate = new Date(date);
    const currentDate = new Date();
    const currentWeekStart = new Date(props.currentWeekStart);
    const currentWeekEnd = new Date(currentWeekStart);
    const nextWeekStart = new Date(currentWeekStart);
    const nextWeekEnd = new Date(nextWeekStart);
    
    
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);
    nextWeekEnd.setDate(nextWeekEnd.getDate() + 13);
    currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

    incomingDate.setHours(0, 0, 0, 0);
    currentWeekStart.setHours(0, 0, 0, 0);
    currentWeekEnd.setHours(0, 0, 0, 0);

    //const isFridayPast9PM = currentDate.getDay() === 5 && currentDate.getHours() >= 21;
    const cutOffTimeCrossed = currentDate.getDay() === parseInt(process.env.REACT_APP_CUTOFFDAY) && currentDate.getHours() >= parseInt(process.env.REACT_APP_CUTOFFHRS);
    // console.log('process.env.CUTOFFDAY: ', process.env.REACT_APP_CUTOFFDAY)
    // console.log('process.env.CUTOFFHRS: ', process.env.REACT_APP_CUTOFFHRS)
    // console.log('cutOffTimeCrossed: ', cutOffTimeCrossed)
    const isWeekend = currentDate.getDay() === 6 || currentDate.getDay() === 0;

    if ((isWeekend || cutOffTimeCrossed) && incomingDate > currentWeekEnd && incomingDate <= nextWeekEnd) {
      return true;
    }
    return incomingDate <= currentWeekEnd;
    //return incomingDate >= currentWeekStart && incomingDate <= currentWeekEnd;
  };

  const getCheckboxStyle = (disabled, isChecked) => {
    return {
      opacity: disabled && !isChecked ? 0.5 : 1,
      cursor: disabled ? 'not-allowed' : 'pointer',
    };
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);

  const handleFeedbackOpen = (menu) => {
    setSelectedMenu(menu);
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setSelectedMenu(null);
    setRatings({});
    setComments({});
  };

  const handleRatingChange = (menuItemId, value) => {
    setRatings((prevRatings) => ({ ...prevRatings, [menuItemId]: value }));
  };

  const handleCommentChange = (menuItemId, value) => {
    setComments((prevComments) => ({ ...prevComments, [menuItemId]: value }));
  };

const handleSubmitFeedback = async () => {
  const feedbackData = selectedMenu.map((menuItem) => ({
    scheduleId: menuItem.ScheduleId,
    rating: ratings[menuItem.ScheduleId] || '',
    comment: comments[menuItem.ScheduleId] || '',
    food: menuItem.food
  }));
  const storedUserId = localStorage.getItem('userId');
  let date = props.date;
  console.log('Feedback Data:', feedbackData);
  console.log('storedUserId:', storedUserId);
  console.log('date:', props.date);

  for (const feedback of feedbackData) {
    const { scheduleId, rating, comment, food } = feedback;
    if((rating != '' && rating != null) || (comment != '' && comment != null) ){
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/saveFeedback`, 
                        { food: food, date: date, ScheduleId: scheduleId, UserId: storedUserId, comments: comment, rating: rating, currentUser: currentUser, currentUserName:currentUserName });
        if (response.data.success) {
          console.log(`Feedback for ScheduleId ${scheduleId} saved successfully.`);
          handleFeedbackClose();
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
  handleFeedbackClose();
};


  return (
<MDBContainer style={{ marginBottom: '10px' }}>
  <MDBCard style={{ height: 'fit-content', width: '100%' }}>
    <MDBCardBody>
      <MDBRow className="g-0">
        <MDBCol xs="12" sm="4" md="4" lg="3">
          <MDBCard style={{ marginRight: '1%' }} className="d-flex flex-column h-100">
            <MDBCardBody className="p-0">
              <div className="d-flex flex-column align-items-center">
                <p className="text-muted text-center w-100" style={{ backgroundColor: '#b7cca1' }}>
                  {formatDate()[0]}
                </p>
                <div className="d-flex align-items-center">
                  <h2 className="mb-0" style={{ marginRight: '10px' }}>
                    {formatDate()[2]}
                    <span className="text-success" style={{ fontSize: '1.3rem' }}>
                      <MDBIcon icon="arrow-up" className="ms-1" size="sm" />
                      <span> {formatDate()[1]}</span>
                    </span>
                  </h2>
                  
                  {props.menus && props.menus.length > 0 && props.thali > 0 && (
                    <div className="d-flex align-items-center ms-2">
                    <IconButton
                      aria-label="feedback"
                      onClick={() => handleFeedbackOpen(props.menus)}
                      disabled={handleFeedbackDisabled()}
                      style={{ padding: '0 5px' }}
                    >
                      <AddCommentIcon 
                        style={{ color: handleFeedbackDisabled() ? 'gray' : 'green' }} 
                        size="sm" 
                      />
                    </IconButton>
                    {props.menus && props.menus.some(menuItem => menuItem.description) && (
                      <IconButton
                        aria-label="information"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        style={{ padding: '0 5px' }}
                      >
                        <InfoIcon color="success" size="sm" />
                      </IconButton>)}
                    </div>
                  )}
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xs="12" sm="8" md="8" lg="9">
          <MDBCard style={{ height: '100%', marginRight: '1%', position: 'relative' }}>
            {props.menus && props.menus.length > 0 && (
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={popoverOpen}
                anchorEl={popoverAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box sx={{ p: 2 }}>
                {props.menus.map((menuItem, index) => (
                  menuItem.description && (
                    <Typography key={index}>
                      {menuItem.food} - {menuItem.description}
                    </Typography>
                  )
                ))}
                </Box>
              </Popover>
            )}
            <MDBCardBody style={{ paddingRight: '18%', paddingLeft: '18%' }}>
              <div>
                {props.menus && props.menus.length > 0 ? (
                  <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {props.menus.map((menuItem, index) => (
                        <li key={index} style={{ display: 'flex', marginBottom: '2%', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label style={{ paddingRight: '10%', textAlign: 'left', minWidth: '50%', maxWidth: '80%' }} onClick={(e) => e.preventDefault()}>{menuItem.food}</label>
                          <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            checked={props.selectedValues[menuItem.ScheduleId]}
                            onChange={() => handleChange(menuItem.ScheduleId)}
                            onClick={(e) => e.stopPropagation()}
                            disabled={isMenuLocked(props.date)}
                            style={{
                              width: '22px',
                              height: '22px',
                              marginRight: '5px',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              ...getCheckboxStyle(isMenuLocked(props.date), props.selectedValues[menuItem.ScheduleId])
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div>No Menu Available</div>
                )}
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBCardBody>

    {/* Feedback Modal */}
    <Modal
      open={feedbackOpen}
      onClose={handleFeedbackClose}
      aria-labelledby="feedback-modal-title"
      aria-describedby="feedback-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="feedback-modal-title" variant="h6" component="h2">
          Menu Feedback
        </Typography>
        <form>
          {selectedMenu && selectedMenu.map((menuItem, index) => (
            <FormControl component="fieldset" key={index} fullWidth margin="normal">
              <FormLabel component="legend">{menuItem.food}</FormLabel>
              <RadioGroup
                row
                aria-label={`rating-${menuItem.ScheduleId}`}
                name={`rating-${menuItem.ScheduleId}`}
                value={ratings[menuItem.ScheduleId] || ''}
                onChange={(e) => handleRatingChange(menuItem.ScheduleId, e.target.value)}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)} />
                <FormControlLabel value="2" control={<Radio />} label="2" disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)} />
                <FormControlLabel value="3" control={<Radio />} label="3" disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)} />
                <FormControlLabel value="4" control={<Radio />} label="4" disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)} />
                <FormControlLabel value="5" control={<Radio />} label="5" disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)} />
              </RadioGroup>

              <TextField
                label="Comment"
                multiline
                rows={1}
                fullWidth
                variant="outlined"
                margin="normal"
                value={comments[menuItem.ScheduleId] || ''}
                onChange={(e) => handleCommentChange(menuItem.ScheduleId, e.target.value)}
                disabled={handleFeedbackFormDisabled(menuItem.ScheduleId)}
                inputProps={{ maxLength: maxChars }}
              />
            </FormControl>
          ))}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={handleFeedbackClose} color="secondary" style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button onClick={handleSubmitFeedback} color="success" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>

  </MDBCard>
</MDBContainer>


  );
}

export default DailyMenu;
