import React, { useState, useEffect } from 'react';
import Roles from './components/Roles';
import Privileges from './components/Privileges';
import RolePrivileges from './components/RolePrivileges';
import UserRoles from './components/UserRoles';
import Header from './components/Header';
import Footer from './components/Footer';

function RolesAndPrivileges() {
  const [activeComponent, setActiveComponent] = useState('UserRoles');

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Roles':
        return <Roles />;
      case 'Privileges':
        return <Privileges />;
      case 'RolePrivileges':
        return <RolePrivileges />;
      case 'UserRoles':
        return <UserRoles />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div style={{
        marginTop: '10vh',
        padding: '',
      }}>
        <nav style={{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}>
          <button
            style={{
              backgroundColor: activeComponent === 'Roles' ? 'green' : '#3973c6',
              color: 'white',
              margin: '5px 0',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              flex: '0 0 auto',
              maxWidth: '200px',
            }}
            onClick={() => setActiveComponent('Roles')}
          >
            Roles
          </button>
          <button
            style={{
              backgroundColor: activeComponent === 'RolePrivileges' ? 'green' : '#3973c6',
              color: 'white',
              margin: '5px',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              flex: '0 0 auto',
              maxWidth: '200px',
            }}
            onClick={() => setActiveComponent('RolePrivileges')}
          >
            Role Privileges
          </button>
          <button
            style={{
              backgroundColor: activeComponent === 'UserRoles' ? 'green' : '#3973c6',
              color: 'white',
              margin: '5px',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              flex: '0 0 auto',
              maxWidth: '200px',
            }}
            onClick={() => setActiveComponent('UserRoles')}
          >
            User Roles
          </button>
        </nav>
        <div>
          {renderComponent()}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RolesAndPrivileges;
