import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import Header from './components/Header';
import Footer from './components/Footer';
import DailyMenu from './components/DailyMenu';

const Home = (props) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [userName, setUserName] = useState(localStorage.getItem('username') || '');
  const [zone, setZone] = useState(localStorage.getItem('zone') || '');
  const [zonalIncharge, setZonalIncharge] = useState(localStorage.getItem('zonalIncharge') || '');
  const [zoneAddress, setZoneAddress] = useState(localStorage.getItem('zoneAddress') || '');
  const [permissions, setPermissions] = useState([]);
  const [todayMenu, setTodayMenu] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [transformedSelectedValues, setTransformedSelectedValues] = useState({});
  const navigate = useNavigate();
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {

    if (userId) {
     // console.log(userId);
      getUserPermissions();
      getUpdatedUserZone();
      getTodayMenu();
      getFeedback();
      
      //console.log(todayMenu);
      
    }
    else {
      // setRoleId(null);
      navigate('/login');
    }
  }, []);

  const transformFeedBackData = (feedback) => {
    const newRatings = {};
    const newComments = {};

    feedback.forEach(item => {
      newRatings[item.ScheduleId] = item.rating;
      newComments[item.ScheduleId] = item.comments;
    });

    setRatings(newRatings);
    setComments(newComments);
    //console.log(ratings,comments)
  };

  const getFeedback = async () => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/getFeedback', { userId });
      if (response.status = 200) {
        console.log(response)
        transformFeedBackData(response.data)
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };
  
  const getUserPermissions = async () => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserPermissions`, { userId });
      if (response.data.success) {
        setPermissions(response.data.permissions);
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getTodayMenu = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getTodayMenu`, { userId });
      if (response.data.success) {

        const parsedMenu = response.data.menu.map(item => ({
          ...item,
          ScheduleItems: JSON.parse(item.ScheduleItems)
        }));

        const selectedValues = parsedMenu.flatMap(item =>
          item.ScheduleItems.filter(scheduleItem => scheduleItem.Quantity === 1)
        );

        setTodayMenu(parsedMenu);
        //console.log(todayMenu);
        setSelectedValues(selectedValues.map(item => item.ScheduleId));

        setTransformedSelectedValues(transformSelectedValues(selectedValues));
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const transformSelectedValues = (selectedValues) => {
    return selectedValues.reduce((acc, item) => {
      acc[item.ScheduleId] = item.Quantity > 0;
      return acc;
    }, {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const getUpdatedUserZone = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUpdatedUserZone`, { userId });
      if (response.data.success) {
        setZone(response.data.Zone);
        setZonalIncharge(response.data.ZonalIncharge);
        setZoneAddress(response.data.Address);
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const viewSchedule = () => navigate('/schedule');
  const viewZones = () => navigate('/Zone');
  const viewUserZones = () => navigate('/UserZone');
  const updateMenu = () => navigate('/MenuMaker');
  const updateRoles = () => navigate('/RolesAndPrivileges');
  const manageUsers = () => navigate('/ManageUser');

  const viewReport = () => {
    if (permissions.includes('VIEW_ADMIN_REPORT') ||
        permissions.includes('VIEW_COOK_REPORT') ||
        permissions.includes('VIEW_DELIVERY_DRIVER_REPORT') ||
        permissions.includes('VIEW_MY_ZONAL_INCHARGE_REPORT') ||
        permissions.includes('VIEW_ALL_ZONAL_INCHARGE_REPORT') ||
        permissions.includes('VIEW_FEEDBACK_REPORT')) 
      navigate('/Report');
    // else if (permissions.includes('COOK')) navigate('/cookReport');
    // else if (permissions.includes('ZONAL_INCHARGE')) navigate('/ZonalInchargeReport');
    // else if (permissions.includes('MENU_MAKER')) navigate('/MenuMakerReport');
    // else if (permissions.includes('DELIVERY_MANAGER')) navigate('/DeliveryManagerReport');
    // else if (permissions.includes('DELIVERY_DRIVER')) navigate('/DeliveryDriverReport');
  };


  return (
    <>
      <Header />
      <Container style={{ marginTop: '70px', paddingBottom: '25%' }}>
        <Row>
          <Col><h3>Welcome {userName}</h3></Col>
        </Row>
        <Row>
          <Col>
            {zone ? (
              <p style={{ color: '#7e0833' }}>
                Your assigned Zone is: {zone}<br />
                Zonal Incharge: {zonalIncharge}<br />
                Zone Address: {zoneAddress}
              </p>
            ) : (
              <p>No zone has been assigned to you, please reach out to the Admin.</p>
            )}
          </Col>
        </Row>

        {/* ------------------------------------------------------------------ */}
        <hr />
        <Row>
          <Col><h3>Today's Menu</h3></Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={8}>
          <MDBContainer style={{ marginBottom: '10px', padding:'10'}}>
          { todayMenu.length > 0 ?
            (todayMenu.map((menuItem, index) => (
              <DailyMenu
                key={index}
                date={formatDate(menuItem.Date)}
                menus={menuItem.ScheduleItems}
                thali = {1}
                selectedValues={transformedSelectedValues}
                currentWeekStart={new Date().toISOString().split('T')[0]}
                ratings = {ratings}
                comments = {comments}
              />
            ))) :
            (
              // <p>No Thaali today</p>
              <DailyMenu
                key={1}
                date={formatDate( new Date())}
                menus={[
                  {ScheduleId: '0', food: 'No Thaali today', Quantity: null}, {}
                ]}
                thali = {0}
                selectedValues={[]}
                currentWeekStart={new Date().toISOString().split('T')[0]}
                
                ratings = {ratings}
                comments = {comments}
              />
            )
          }
        </MDBContainer>
          </Col>
        </Row>
  

        <hr />
        {/* ------------------------------------------------------------------ */}

        <Row>
          <Col><h3>Menu</h3></Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col xs={12} sm={6} md="auto" className="mb-2">
            <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewSchedule}>Select Menu</Button>
          </Col>
          {(permissions.includes('MENU_MAKER') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={updateMenu}>Update Menu</Button>
            </Col>
          )}
        </Row>



        {permissions.includes('VIEW_REPORT') && (
          <>
            <hr />
            <Row>
              <Col><h3>Reports</h3></Col>
            </Row>
            <Row className="justify-content-center mb-4">

              <Col xs={12} sm={6} md="auto" className="mb-2">
                <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewReport}>View Reports</Button>
              </Col>
            </Row>
          </>
        )}


        {(permissions.includes('VIEW_ZONES') || permissions.includes('VIEW_USER_ZONES') || permissions.includes('UPDATE_ROLES') || permissions.includes('MANAGE_USER')
          || permissions.includes('ADMIN')) && (
            <>
              <hr />
              <Row>
                <Col><h3>Manage Entities</h3></Col>
              </Row>
            </>
          )}

        <Row className="justify-content-center">
          {(permissions.includes('VIEW_ZONES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewZones}>Manage Zones</Button>
            </Col>
          )}
          {(permissions.includes('VIEW_USER_ZONES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewUserZones}>Manage User Zones</Button>
            </Col>
          )}
          {(permissions.includes('UPDATE_ROLES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={updateRoles}>Manage Roles</Button>
            </Col>
          )}
          {(permissions.includes('MANAGE_USER') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={manageUsers}>Manage Users</Button>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
