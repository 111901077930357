import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useRef,useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WeeklySchedule from './components/WeeklySchedule';
import CustomModal from './components/CustomModal';
import Header from './components/Header';
import Footer from './components/Footer';
import CircularProgress from '@mui/material/CircularProgress';

const MominHome = (props) => {
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState([]);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedValues, setSelectedValues] = useState({});
  const [changedItems, setChangedItems] = useState([]);

  const [modalType, setModalType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthsWithNoItems, setMonthsWithNoItems] = useState([]);

  const scheduleWrapperRef = useRef(null);
  const [buttonPosition, setButtonPosition] = useState({ right: '2%' });

  useEffect(() => {
    if (scheduleWrapperRef.current) {
      const rect = scheduleWrapperRef.current.getBoundingClientRect();
      setButtonPosition({ right: `${window.innerWidth - rect.right + 20}px` }); // Adjust the 20px as needed for padding
    }
    const handleResize = () => {
      if (scheduleWrapperRef.current) {
        const rect = scheduleWrapperRef.current.getBoundingClientRect();
        setButtonPosition({ right: `${window.innerWidth - rect.right + 20}px` });
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const transformData = (data) => {
     // console.log(data)
      const transformedData = data.map(item => ({
        Date: item.Date,
        // ScheduleItems: JSON.parse(item.ScheduleItems)
        ScheduleItems: JSON.parse(item.ScheduleItems.replace(/\\r\\n/, ''))
      }));
      //console.log(transformData);
      return transformedData;
    };
    const transformFeedBackData = (feedback) => {
      const newRatings = {};
      const newComments = {};
  
      feedback.forEach(item => {
        newRatings[item.ScheduleId] = item.rating;
        newComments[item.ScheduleId] = item.comments;
      });
  
      setRatings(newRatings);
      setComments(newComments);
      //console.log(ratings,comments)
    };
    

    const getSchedule = async () => {
      setLoading(true);
      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/getSchedule', { userId });
        if (response.status = 200) {
          setSchedule(transformData(response.data));
          setMessage(response.data.message);
        } else {
          setMessage(response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
      setLoading(false);
    };
    const getFeedback = async () => {
      setLoading(true);
      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/getFeedback', { userId });
        if (response.status = 200) {
      //    console.log(response)
          transformFeedBackData(response.data)
          setMessage(response.data.message);
        } else {
          setMessage(response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
      setLoading(false);
    };

    if (storedUserId) {
      setUserId(storedUserId);
     // console.log(userId)
      // setRoleId(storedRoleId);
      // SetUserName(storedUserName)
    } else {
      navigate('/login')
    }

    if (userId) {
      getSchedule();
      getFeedback();
    }


  }, [userId]);


  useEffect(() => {
    if (schedule.length > 0) {
      const initialSelectedValues = {};
      schedule.forEach(menuItem => {
        menuItem.ScheduleItems.forEach(item => {
          if (item.Quantity > 0) {
            initialSelectedValues[item.ScheduleId] = true;
          } else {
            initialSelectedValues[item.ScheduleId] = false;
          }
        });
      });

      setSelectedValues(initialSelectedValues);
    }
  }, [schedule]);


  const handleUserResponse = (confirmed) => {
    if (confirmed) {
      updateOrder();
    }
    handleCloseModal();
  };

  const updateOrder = async () => {
    
    setLoading(true);
    for (let i = 0; i < changedItems.length; i++) {
      const scheduleId = changedItems[i].ScheduleId;
      const quantity = changedItems[i].Quantity ? 1 : 0;
      const orderStatus = 'Confirmed';

      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/updateOrder', { userId, scheduleId, quantity, orderStatus });
        if (response.data.success) {
          setMessage(response.data.message);
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    setLoading(false);
    handleOpenModal('order');
  };
  


const getMonthsForDatesWithNoItems = (schedule, selectedValues) => {
  const today = new Date();
  const nextWeekMonday = new Date(today);
  nextWeekMonday.setDate(nextWeekMonday.getDate() + (1 + 7 - today.getDay()) % 7); 

  const scheduleDates = schedule
      .filter(({ Date: scheduleDate }) => new Date(scheduleDate) >= nextWeekMonday) 
      .map(({ Date: scheduleDate }) => new Date(scheduleDate));

  const scheduleIdsByDate = {};
  const monthsWithNoItems = new Set();

  for (const date of scheduleDates) {
      scheduleIdsByDate[date.toDateString()] = [];
  }

  for (const { Date: scheduleDate, ScheduleItems } of schedule) {
      const currentDate = new Date(scheduleDate);
      if (currentDate < nextWeekMonday) continue; 
      const scheduleIds = ScheduleItems.map(item => item.ScheduleId);
      scheduleIdsByDate[currentDate.toDateString()].push(...scheduleIds);
  }

  for (const date in scheduleIdsByDate) {
      const scheduleIds = scheduleIdsByDate[date];

      if (!scheduleIds.some(id => selectedValues[id])) {
          const month = new Date(date).toLocaleString('default', { month: 'long' });
          monthsWithNoItems.add(month);
      }
  }

  return Array.from(monthsWithNoItems);
};



  const handleSubmit = async () => {
    try {
      const monthsWithNoItems = getMonthsForDatesWithNoItems(schedule, selectedValues);
      if (monthsWithNoItems.length>0) {
        setMonthsWithNoItems(monthsWithNoItems);
        handleOpenModal('Confirmation');
        return;
      }
      else
      //alert('updating order')
      updateOrder();

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCheckboxChange = (newSelectedValues) => {
    setSelectedValues(newSelectedValues);
  };

  const handleChangedItems = (scheduleId, quantity) => {
    setChangedItems(prevState => {
      const existingItemIndex = prevState.findIndex(item => item.ScheduleId === scheduleId);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevState];
        updatedItems[existingItemIndex].Quantity = quantity;
        return updatedItems;
      } else {
        return [...prevState, { ScheduleId: scheduleId, Quantity: quantity }];
      }
    });
  };

  return (
    <div>
      
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress style={{ color: 'green' }} />
          {/* <p style={{ color: 'white' }}>loading...</p> */}
        </div>
      )}
      <div>
        {modalType === 'order' && (
          <CustomModal
            open={modalOpen}
            onClose={handleCloseModal}
            modalType="order"
          />
        )}

        {modalType === 'Confirmation' && (
          <CustomModal
            open={modalOpen}
            onClose={handleCloseModal}
            modalType="confirmation"
            onYesClick={() => handleUserResponse(true)}
            onNoClick={() => handleUserResponse(false)}
            monthsWithNoItems={monthsWithNoItems}
          />
        )}
      </div>
      <Header></Header>
      <div style={{ minHeight: 'calc(100vh - 56px)', overflowY: 'auto' }}>
      <Container style={{ width: '100%', display: 'flex', flexDirection: 'column', maxWidth: '900px',paddingBottom: '15vh' }}>
        <Row style={{ flex: '1' }}>
          <Col xs={12}>
            <div ref={scheduleWrapperRef}>
              {schedule.length > 0 && (
                <>
                  <Row>
                    <WeeklySchedule
                      menu={schedule}
                      selectedValues={selectedValues}
                      ratings = {ratings}
                      comments = {comments}
                      handleCheckboxChange={handleCheckboxChange}
                      handleChangedItems={handleChangedItems}
                    />
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          position: 'fixed',
          bottom: '12%',
          right: buttonPosition.right,
          zIndex: '1000',
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: 'green',
            //marginBottom: '20%',
          }}
          onClick={handleSubmit}
          value={'submit'}
        >
          Submit
        </Button>
      </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default MominHome